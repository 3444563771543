<template>
  <div>
    <div id="mySidebar" class="sidebar" v-if="user">
      <ul class="menu-list">
        <li class="menu-item" v-if =!enterpriseAdmin>
          <router-link  to="/registry" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/home.png" alt="" />
            </div>
            <div class="item-name">Registry</div>
          </router-link>
        </li>
        <li class="menu-item" v-if =enterpriseAdmin>
          <router-link  to="/registries" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/home.png" alt="" />
            </div>
            <div class="item-name">Registry</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="enterpriseAdmin && user.enterpriseCompany !== null && user.user.secret_answer_status !== null">
          <router-link
            v-if="user.enterpriseCompany && user.enterpriseCompany.nda_status != 1"
            to="/globalnda-signup"
            class="menu-link"
          >
            <div class="item-icon">
              <img src="../../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </router-link>
          <a @click="checkNdaStatus" v-else to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </a>
        </li>

        <li class="menu-item" v-else-if="enterpriseAdmin || enterpriseUser">
          <a @click="checkNdaStatus" to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </a>
        </li>
        <li class="menu-item" v-if="!enterpriseAdmin && user.enterpriseAgreement != null">
          <router-link to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/file.png" alt="" />
            </div>
            <div class="item-name">My Agreement</div>
          </router-link>
        </li>
        
        <li class="menu-item" v-else-if="!enterpriseAdmin">
          <a @click="checkAgreement" to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/file.png" alt="" />
            </div>
            <div class="item-name">My Agreement</div>
          </a>
        </li>
        <li class="menu-item" v-show="enterpriseAdmin">
          <router-link to="users-signed"   class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/user-withouttick.png" alt="" class="usertab"/>
            </div>
            <div class="item-name">Users</div>
          </router-link>
        </li>
        <li class="menu-item" v-show="enterpriseAdmin">
          <router-link to="/Companies"  class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/file.png" alt=""/>
            </div>
            <div class="item-name">Companies</div>
          </router-link>
        </li>
        <li class="menu-item" v-show="enterpriseAdmin">
          <router-link to="/templates"  class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/file.png" alt=""/>
            </div>
            <div class="item-name">Templates</div>
          </router-link>
        </li>
        <li class="menu-item" v-for="(card, index) in cardData" :key="index" v-show="enterpriseUser">
          <div @click="openEnvelope(card)" class="menu-link">
            <div class="item-icon">
              <img src="../../../assets/images/file.png" alt=""/>
            </div>
            <div class="item-name">{{ card.template_name }}</div>
          </div>
        </li>
      </ul>
      <div class="closebtn" @click="closeNav()">
        <img src="../../../assets/images/Collapse.png" alt="" />
      </div>
      <div id="main" @click="openNav()">
        <img src="../../../assets/images/Collapse.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "LeftMenu",
  data() {
    return {
      isAdmin: false,
      isVerfier: false,
      enterpriseAdmin: false,
      enterpriseUser: false,
      cardData: [],
      enterpriseId: '',
      enterpriseFoxit: {},
      templateData: {},

    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    openEnvelope(card) {
      const currentUrl = window.location.href;
      const formData = new FormData();
      formData.append("template_id", card.template_id);
      formData.append("redirectUrl", currentUrl);
      this.showLoader = 1;

      axios.post(process.env.VUE_APP_API_URL + "enterprise/agreement/creatEvelopFromTemplate", formData)
        .then((response) => {
          // console.log('API Response:', response);
          if (response.data.statusCode === 200) {
            if (response.data.data) {
              this.templateData = response.data.data.embedded_session_url;
              console.log(this.templateData);
              setTimeout(() => {
                this.$root.$emit("createTemplate", this.templateData);
              }, 500);
              this.$router.push({ name: "PDFView" });
              this.showLoader = 0;
            } else {
              this.$swal({ title: response.data.message, icon: "info", showCancelButton: false });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            alert(error.response.data.message);
          } else {
            console.error('API Error:', error);
          }
        });
    },
    checkNdaStatus() {
      if (this.user.enterpriseCompany != null &&  this.user.user.secret_answer_status != null) {
          if (this.user.enterpriseCompany.nda_status == 1) {
            this.$swal({
              title: "No new agreements to sign.",
              icon: "info",
              showCancelButton: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "EnterpriseSignedAgreements" });
                }
            });
          }
      } else if(this.user.enterpriseCompany == null) {
        this.$swal({
          title: "Please fill out your company details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "EnterpriseCompanyProfilePage" });
          }
        });
      }else if( this.user.user.secret_answer_status == null) {
        this.$swal({
          title: "Please fill out user details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "EnterpriseProfile" });
          }
        });
      }
    },
    checkNdaStatusRegistry() {
      this.$router.push({ name: "EnterpriseHomes" });
    },
    checkAgreement() {
      if (this.user.enterpriseCompany == null) {
        this.$swal({
          title: "Please fill out your company details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "EnterpriseCompanyProfilePage" });
          }
        });
      } else if (this.user.user.secret_answer_status == null) {
        this.$swal({
          title: "Please fill out user details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "EnterpriseProfile" });
          }
        });
      } else {
        this.$swal({
        text: "Let's sign your first GlobalNDA.",
        iconHtml: '<img src=""">',
        customClass: { icon: "ico" },
        confirmButtonText: "eSign",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
            this.$router.push({ name: "EnterpriseGlobalNDAForm"});
        }
      });
      }
    },
 
    openNav: function () {
      var x = window.matchMedia("(min-width: 769px)");

      if (x.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:180px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document
          .querySelector(".registry-wrapper")
          .setAttribute("style", "width:calc(100vw - 180px)");
        document
          .querySelector(".home-wrapper")
          .setAttribute("style", "width:calc(100vw - 180px)");
      }

      var y = window.matchMedia("(max-width: 768px)");

      if (y.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:50px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document
          .querySelector(".registry-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
        document
          .querySelector(".home-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
      }
    },

    closeNav: function () {
      document.getElementById("mySidebar").setAttribute("style", "width:0");
      document.getElementById("main").setAttribute("style", "display:block");
      document.querySelector(".closebtn").setAttribute("style", "display:none");
      document.querySelector(".registry-wrapper").setAttribute("style", "width:100vw");
      document.querySelector(".home-wrapper").setAttribute("style", "width:100vw");
    },
    getTemplates() {
      this.showLoader = 1;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}enterprise/allEnterpriseTemplates?enterprise_id=${this.enterpriseId}`, {})
            .then((response) => {
          if (response.data.statusCode === 200) {
            this.cardData = response.data.data.listing;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    }
    
  },

  mounted() {
    const enterpriseId = localStorage.getItem("enterprise_id");
    if (enterpriseId !== null && enterpriseId !== undefined) {
      console.log("enterprise_id from localStorage:", enterpriseId);
      this.enterpriseId = enterpriseId;
      this.getTemplates();
    } else {
      console.log("enterprise_id not found in localStorage");
    } 
    
    if (localStorage.getItem("permissions") == 1) {
    this.isAdmin = true;
    } else if (localStorage.getItem("permissions") == 4) {
        this.isVerifier = true;
    } else if (localStorage.getItem("permissions") == 5 || localStorage.getItem("permissions") == 8) {
        this.enterpriseAdmin = true;
    } else if (localStorage.getItem("permissions") == 6 || localStorage.getItem("permissions") == 7) {
        this.enterpriseUser = true;
    }
  }
}
</script>

<style scoped>
.usertab {
  height: 17px;
  width: 20px
}
</style>
