<template>
  <div>
    <div class="registry-sign-up-form mb-5">
      <form>
        <div class="registry-sign-up-form-content">
          <div>
            <h5 class="title">GlobalNDA Registration</h5>
            <div class="container-fluid mt-4">
              <div class="row">
                <div class="col-lg-6 p-0 pr-lg-3">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Legal Verifier Contact Name<span class="astrick"
                            >*</span
                          ></span>
                      </div>
                    </label>
                    <input
                      v-if="disabled"
                      @click="beforeSign"
                      type="text"
                      class="form-control"
                      id="legal_name"
                      readonly
                      placeholder="Enter Name"
                      autocomplete="off"
                      v-model.trim="$v.details.legal_name.$model"
                    />
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      id="legal_name"
                      disabled
                      placeholder="Enter Name"
                      autocomplete="off"
                      v-model.trim="$v.details.legal_name.$model"
                      :class="{
                        'is-invalid': $v.details.legal_name.$error,
                        'is-valid': !$v.details.legal_name.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.legal_name.required"
                        >Name is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Legal Verifier Contact Title<span class="astrick"
                            >*</span
                          ></span
                        >
                      </div>
                    </label>
                    <input
                    v-if="disabled"
                    @click="beforeSign"
                      type="text"
                      class="form-control cur-disable"
                      id="legal_title"
                      readonly
                      placeholder="Enter Title"
                      autocomplete="off"
                      v-model.trim="$v.details.legal_title.$model"
                    />
                    <input
                    v-else
                      type="text"
                      class="form-control"
                      id="legal_title"
                      disabled
                      placeholder="Enter Title"
                      autocomplete="off"
                      v-model.trim="$v.details.legal_title.$model"
                      :class="{
                        'is-invalid': $v.details.legal_title.$error,
                        'is-valid': !$v.details.legal_title.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.legal_title.required"
                        >Title is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 p-0">
                  <div class="form-group">
                    <label for="name">
                      <div class="change-icon">
                        <span class="text"
                          >Legal Verifier Contact Email<span class="astrick"
                            >*</span
                          ></span
                        >
                      </div>
                    </label>
                    <input
                    v-if="disabled"
                    @click="beforeSign"
                      type="email"
                      class="form-control cur-disable"
                      id="legal_email"
                      placeholder="Enter Email"
                      autocomplete="off"
                      readonly
                      v-model.trim="$v.details.legal_email.$model"
                    />
                    <input
                    v-else
                      type="email"
                      class="form-control"
                      id="legal_email"
                      placeholder="Enter Email"
                      autocomplete="off"
                      disabled
                      v-model.trim="$v.details.legal_email.$model"
                      :class="{
                        'is-invalid': $v.details.legal_email.$error,
                        'is-valid': !$v.details.legal_email.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.legal_email.required"
                        >Email is required</span
                      >
                    </div>
                  </div>
                </div>
      <!-- checkbox div disable -->
                <div v-if="disabled">
                      <div class="col-12 form-group d-flex align-items-center justify-content-start">                        
                        <input
                          type="checkbox"
                          class="mr-2 new check"
                          checked
                        />
                        <label class="form-check-label cur-disable" @click="beforeSign" 
                          >I Agree to terms and conditions and Privacy Policy.</label>
                    </div>
                    <div class="col-12 mt-2 form-group d-flex align-items-center justify-content-start">
                      <input
                        type="checkbox"
                        class="mr-2 new check"
                        checked
                      />
                      <label class="form-check-label cur-disable" @click="beforeSign"
                        >I am authorized to eSign for my company.</label>
                    </div>
                </div>
      <!-- checkbox div enable -->
                <div v-else>
                      <div class="col-12 form-group d-flex align-items-center justify-content-start">
                        <input 
                        type="checkbox" 
                        id="termsError"
                        checked
                        :disabled="disabled"
                        @change="showTermsError(); ischecked()"
                        class="checkInput" 
                        >
                        <label class="checkLabel form-check-label mb-0" for="termsError">
                            <span class="checkSpan"></span> I Agree to terms and conditions and Privacy Policy.
                        </label>
                     </div>
                    <p class=" custom-error term-error">
                      Please check terms and conditions checkbox
                    </p>

                    <div
                      class="col-12 mt-2 form-group d-flex align-items-center justify-content-start">
                      <input 
                        type="checkbox" 
                        id="signError"
                        checked
                        :disabled="disabled"
                        @change="showSignError(); ischecked()"
                        class="checkInput" >
                        <label class="checkLabel mb-0" for="signError">
                            <span class="checkSpan"></span> I am authorized to eSign for my company.
                        </label>
                    </div>
                    <p class="custom-error sign-error">
                      Please check eSign checkbox
                    </p>
                </div>
              </div>
            </div>
            <div class="btns d-flex">
              <button class="custom-btn2 new" :disabled="disabled">Cancel</button>
              <!-- before sign -->
              <button
                type="button"
                class="custom-btn2 new "
                id="beforeSign"
                @click="beforeSign "
                v-if="disabled">
                <span>Agree and Sign</span>
              </button>
              
              <!-- if agreement sign -->
              <button
                v-else-if="!agreeBtn"
                type="button"
                class="custom-btn2 new" 
                @click="signAgreement(0, null)"
                :disabled="disabled"
                >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else disabled>Agree and Sign</span>
              </button>
              <!-- if uncheck the checkbox show disable btn -->
              <button
                v-if="agreeBtn"
                type="button"
                class="custom-btn6"                >
                <span>Agree and Sign</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  required,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";
import axios from "axios";


// const number = helpers.regex("serial", /^[0-9]{4}-[0-9]{3}-[0-9]{4}$/);

export default {
  name: "GlobalForm",
  props: {
    detailsForm: Object,
  },
  data: function() {
    return {
      details: {
        full_name: "Global NDA",
        company_name: "",
        start_agreement_date: "",
        end_agreement_date: "",
        start_agreement_date_formated: "",
        legal_name: "",
        legal_title: "",
        legal_email: "",
      },
      isLoadingArray: [],
      companies: [],
      companiesPlaceholder: "Select Company",
      formStatusData: {},
      imageSrc: null,
      sign: "",
      disabled: true,
      event: "",
      folderId: "",
      ndaStatus: "",
      agreeBtn: false,
    };
  },
  validations: {
    details: {
      legal_name: {
        required,
      },
      legal_title: {
        required,
      },
      legal_email: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
...mapActions("auth", ["getUserData"]),
 beforeSign() {
  this.$swal({
      title: "Please sign and click finish to proceed.",
      icon: "info",
      showCancelButton: false,
    })
  },
    showTermsError: function() {
      if (document.getElementById("termsError").checked) {
        document.querySelector(".term-error").style.display = "none";
      } else {
        document.querySelector(".term-error").style.display = "block";
      }
    },

    showSignError: function() {
      if (document.getElementById("signError").checked) {
        document.querySelector(".sign-error").style.display = "none";
      } else {
        document.querySelector(".sign-error").style.display = "block";
      }
    },
    signAgreement: function(index) {
        this.$v.$touch();
        if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      if (
        document.getElementById("termsError").checked &&
        document.getElementById("signError").checked
      ) {
        document.querySelector(".custom-error").style.display = "none";
        document.querySelector(".sign-error").style.display = "none";
      } else {
        document.querySelector(".custom-error").style.display = "block";
        document.querySelector(".sign-error").style.display = "block";
        return;
      }
          this.agreementSigns();
      
    },
    ischecked(){
      if (document.getElementById("termsError").checked && document.getElementById("signError").checked){
        this.agreeBtn = false;
      }
      else{
        this.agreeBtn = true;
      }
    },
    agreementSigns() {
      var formData = new FormData();
      formData.append("folderId", this.folderId);
      formData.append("event", this.event);
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/agreement/create", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getUserData();
            this.$router.push({ name: "EnterpriseHome"});
            this.$set(this.isLoadingArray, 0, false);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, 0, false);
          }
        });
    },
  },
  watch: {
    $data: {
      handler: function() {
        this.event = this.detailsForm.folder_status;
        this.folderId = this.detailsForm.folder_id;
        if (this.detailsForm.status == 2) {
          this.disabled = false;
        } else if (this.detailsForm.status == 1) {
          this.disabled = true;
        } else {
          this.disabled = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.getUserData();
  },
  mounted() {
    this.agreementSigns();

    // this.$root.$on("signAgreementEnterprise", () => {
    //   this.agreementSigns();
    // });

    this.$root.$on("dataRegistry", (data) => {
      if (data) {
        this.formStatusData = data;
        this.event = this.formStatusData.folder_status;
        this.folderId = this.formStatusData.folder_id;
        if (this.formStatusData.status == 2) {
          this.disabled = false;
        } else if (this.formStatusData.status == 1) {
          this.disabled = true;
        } else {
          this.disabled = true;
        }
      }
    });
    if (this.user) {
      this.details.company_name = this.user.enterpriseCompany.company_name;
      this.details.title = this.user.user.title;
      this.details.legal_name = this.user.enterpriseCompanyLegal.legal_contact_name;
      this.details.legal_title = this.user.enterpriseCompanyLegal.legal_contact_title;
      this.details.legal_email = this.user.enterpriseCompanyLegal.legal_contact_email;
      this.details.registry_id = this.user.enterpriseCompany.id;
    }
  },
};
</script>

<style scoped>
.form-group .vs__search {
  border: none !important;
  border-radius: 6px;
  padding: 5px !important;
  resize: none;
  box-shadow: none;
}
.new:disabled {
  cursor: not-allowed;
}

.checkInput:checked + .checkLabel > .checkSpan::before {
    top: 2px;
}
</style>