<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper container-fluid">
        <div class="row">
          <div class="col-12 px-0" v-if="loading">
            <div
              v-if="showloader == 0"
              class="lds-dual-ring-registry"
              id="loading"
            ></div>
          </div>
          <div class="col-lg-6 px-0 pl-lg-0 pr-lg-2" v-else>
            <GlobalForm v-if="details" :detailsForm="details" />
          </div>
          <div class="col-lg-6 mt-3 px-0" v-if="details">
            <NDAForm :details="details" :sign="sign" :loadingDiv="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import GlobalForm from "../../components/Enterprise/globalndafrom/GlobalForm.vue";
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import NDAForm from "../../components/Enterprise/ndaform/NDAForm.vue";

export default {
  name: "EnterpriseGlobalNDAForm",

  components: {
    Navbar,
    LeftMenu,
    GlobalForm,
    NDAForm
  },
  data() {
    return {
      details: {},
      sign: "",
      showloader: 0,
      loading: true,
    };
  },
  mounted() {
    this.$root.$on("dataForndaForm", (data, sign) => {
      this.details = data;
      this.sign = sign;
      this.loading = false;
    });
    // localStorage.getItem("ListlastPage");
  },
};
</script>

<style scoped>
.lds-dual-ring-registry {
  position: absolute;
  z-index: 999;
  padding-top: 50% !important;
}
</style>
