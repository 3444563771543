<template>
  <div>
    <div class="agreement-top d-none d-md-flex">
      <div id="navbar" class="d-flex align-items-center justify-content-end" v-if="loadingDiv==false">
        <button type="button" class="mr-3" @click="zoomin()">
          <i class="fa fa-search-plus" aria-hidden="true"></i>
        </button>
        <button type="button" @click="zoomout()">
          <i class="fa fa-search-minus" aria-hidden="true"></i>
        </button>
      </div>
      <!-- <button
        type="button"
        id="navbar"
        class="custom-btn2"
        v-if="packExist == false"
        @click="showCard(null, 'Card Details')"
      >
        Save as PDF
      </button>
      <button type="button" class="custom-btn2" v-if="packExist == true">
        Save as PDF
      </button> -->
    </div>

    <!-- <div
      class="form_overlay"
      v-if="ndaStatus !== 2 && packExist === false"
      @click="showCard(null, 'Card Details')"
    ></div> -->

    <div class="e-sign-wrapper"
    >
      <!-- <div
        v-if="showLoader == 1"
        class="lds-dual-ring-registry"
        id="loading"
      ></div> -->
      <iframe
        ref="iframeContent"
        frameborder="0"
        height="900"
        scrolling="yes"
        :src="embeddedSignUrl"
        width="100%"
        id="sigForm"
        v-if="showLoader == 0"
      >
      </iframe>
      <div
        style="font-family: Helvetica, Arial, Verdana, sans-serif;line-height:13px !important;text-align:center;margin-top: 6px !important;"
      ></div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import $ from "jquery"

export default {
  name: "NDAForm",
  data: function() {
    return {
      embeddedSignUrl: "",
      isLoadingArray: [],
      mobilePreview: true,
      foxitAccesstoken: "",
      // packExist: false,
      showLoader: 0,
      event: "",
      folderId: "",
      // ndaStatus: "",
    };
  },
  watch: {
    $data: {
      handler: function() {
        if (document.activeElement.id == "sigForm") {
          alert(1);
        }
      },
      deep: true,
    },
  },
  props: {
    details: Object,
    sign: String,
    loadingDiv: Boolean
  },
  computed: {
    ...mapGetters("auth", ["user", "userDomain"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    // showCard: function(card, modalTitle) {
    //   this.$root.$emit("openCardDetailModal", card, modalTitle);
    // },

    zoomin: function() {
      var myImg = document.getElementById("sigForm");
      var currWidth = myImg.clientWidth;
      if (currWidth == 2500) return false;
      else {
        myImg.style.width = currWidth + 100 + "px";
      }
    },

    zoomout: function() {
      var myImg = document.getElementById("sigForm");
      var currWidth = myImg.clientWidth;
      if (currWidth == 500) return false;
      else {
        myImg.style.width = currWidth - 100 + "px";
      }
    },
    // checkPackDate() {
    //   if(this.user){
    //   if (this.user.renewalData != null) {
    //     let current = moment();
    //     var today = current.format("YYYY-MM-DD");
    //     if (this.user.renewalData.next_renewal === today) {
    //       this.packExist = false;
    //     } else {
    //       this.packExist = true;
    //     }
    //   }
    // }
    // },
    UpdateFormStatus: async function() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("folderId", this.folderId);
    
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/agreement/signSuccess", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.embeddedSignUrl = response.data.data.s3_url;
            // this.ndaStatus = response.data.data.status;
            setTimeout(() => {
              this.$root.$emit("dataRegistry", response.data.data);
              this.$root.$emit("dataForndaForm", response.data.data);
            }, 500);
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.showLoader = 0;
          }
        });
    },
    getAccessToken() {
      this.showLoader = 1;
      const currentUrl = window.location.href;
      var dynamicUrl = {currentUrl}
  

      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/agreement/getFoxitConnection", dynamicUrl)
        .then((response) => {
          this.embeddedSignUrl = response.data.data.embedded_seesion_url;
          // this.ndaStatus = response.data.data.status;
          setTimeout(() => {
            this.$root.$emit("dataRegistry", response.data.data);
            this.$root.$emit("dataForndaForm", response.data.data);
          }, 500);
          this.showLoader = 0;
        })
        .catch((error) => {
          console.log(error);
          this.showLoader = 0;
        });
    },
    getLinkUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.folderId = urlParams.get("folderId");
      this.event = urlParams.get("event");
      if (this.folderId != null) {
        this.UpdateFormStatus();
      } else {
        this.getAccessToken();
      }
    },
  },
  beforeMount(){
    this.getUserData();
  },
  mounted() {
    this.$root.$on("autoUpdatePack", () => {
      // this.ndaStatus = 1;
      // this.packExist = false;
      $('.form_overlay').hide();
    });

    this.getLinkUrl(1);
    // this.checkPackDate();
  },
};
</script>

<style scoped>
.e-sign-wrapper {
  margin-top: -25px;
}

.form_overlay {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* background-color: rgba(0,0,0,0.5); Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.agreement-top {
  position: absolute;
  z-index: 999;
  right: 0px;
  top: -10px;
}
</style>
